@import "@/styles/_variables.scss";

.changeLanguage {
  cursor: pointer;
}

.changeLanguage:hover {
font-weight: bold !important;

}

.footer {
  background-color: #e9e9e9;
  margin-top: 8rem;
  @media (max-width: 1024px) {
    margin-top: 4rem;
  }
  @media (max-width: 576px) {
    margin-top: 2rem;
  }
}

.paddingtopfooter {
  padding-top: 4rem;
  @media (max-width: 1024px) {
    padding-top: 2rem !important;
  }
}

.containerColLinks {
  justify-content: center;
}

.colLinks {
  text-align: center;
}

.accordion_style {
  width: 100%;
  background-color: #e9e9e9;
}

.accordion_header {
  padding: 0;
  background-color: #e9e9e9;
}

// Mobile Accordion each one is a Col in Desktop
.accordiontitle {
  display: flex;
  justify-content: space-between;
}

.accordiontoggle {
  justify-content: space-between;
  border-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  cursor: pointer;
}

.accordiontoggle:hover {
  // color: red;
  text-decoration: underline;
}

.signupInformation {
  display: flex;
  justify-content: space-between;
}

.signupInformation:hover {
  color: #f0250e !important;
}

.socialContainer {
  justify-content: center;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.socialCol {
  max-width: 5rem;
  text-align: center;
}
